import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FinalScreen from './containers/FinalScreen';
import FullScreenLoader from './components/FullScreenLoader';

const NotFoundLayout = lazy(() => import('./containers/NotFoundLayout'));
const Dashboard = lazy(() => import('./containers/Dashboard'));

function App() {
  return (
    <>
      <Suspense fallback={
        <FullScreenLoader />
      }>
        <Routes>
          <Route path="*" element={<NotFoundLayout />} />
          <Route
            path=""
            element={
              <Dashboard />
            }
          />
          <Route
            path="/completed"
            element={
                <FinalScreen />
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
}
export default App;
