import { CircularProgress } from "@mui/material";

function FullScreenLoader(){
    return(
        <div className="w-screen flex items-center justify-center" style={{height: "calc(100vh - 100px)"}}>
            <div>
                <CircularProgress style={{color: "#5956D6"}}/>
            </div>
        </div>
    )
}

export default FullScreenLoader;