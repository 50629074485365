import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import '../containersCss/componentstyles.css'
import { ContentCopy } from "@mui/icons-material";
import notification from "../utils/notifications";
import QRCode from "react-qr-code";
import FullScreenLoader from "../components/FullScreenLoader";
import phoneEmpty from "../assets/phoneEmpty.png";
import whatsappScreen from "../assets/whatsappScreen.png";
import WhatsappIcon from '../assets/WhatsappIcon.png';
import blueticks from "../assets/blue-ticks.svg"
import congratsBackground from "../assets/congratsBackground.svg";
import cornerBorders from "../assets/cornerBorders.svg";
import loveDP from "../assets/loveDP.png";

function FinalScreen({businessDetails}){
    const [showMainLoader, setShowMainLoader] = useState(true);
    const [isError, setIsError] = useState(false);
    const [botDetails, setBotDetails] = useState({
        wabanumber: "+91 XXXXXXXXXX",
        botUrl: "https://wa.me/",
        businessName: "Business Name",
    });

    useEffect(() => {
        if(businessDetails) {
            let waAccountNumber = businessDetails.whatsappBusinessAccountNumber ? businessDetails.whatsappBusinessAccountNumber.replace("-", " ") : '';
            setBotDetails({
                wabanumber: `+${waAccountNumber}`,
                botUrl: `https://wa.me/${waAccountNumber}?text=Hi`,
                businessName: businessDetails.shopName,
            })
            setShowMainLoader(false);
        } else {
            setIsError(true);
        }

        // eslint-disable-next-line
    }, [businessDetails]);

    return(
        <div>
            { showMainLoader ? 
                <FullScreenLoader />
                :
                <div>
                    {
                    isError ? //isError
                    <div className="mt-24 text-center">
                        <p className="text-gray font-medium text-2xl">Something went wrong.</p>
                    </div>
                    :
                    //new design preparation - start
                    <div className="container-fluid mx-4 md:mx-8 lg:mx-16 xl:mx-32 text-black congrats-bg" style={{backgroundImage: "url("+congratsBackground+")"}}>
                       <div className='py-10'>
                            <div className="">
                                <p className="text-2xl font-medium mb-2">Congratulations! 🎉</p>
                                <p className="text-gray">Your WhatsApp Business Account is now <span className=" text-green font-medium">LIVE</span>, here’s what you can do next 👇🏼</p>
                                <p className="text-gray text-sm mt-4"><span className="font-medium">Note: </span>Do not install the WhatsApp mobile App on this number, if you do so, your bot will be disabled.</p>
                            </div>

                            <div className="md:flex flex-wrap justify-between mt-6 gap-4 ">
                                <div className="grow flex justify-center text-center phone-design-container">
                                    <div className="flex flex-col items-center grow mb-8 md:mb-0 phone-design ">
                                        <div style={{backgroundImage: "url("+phoneEmpty+")"}} className="phone-background">
                                            <div style={{backgroundImage: "url("+whatsappScreen+")"}} className="phone-inside">
                                                <div className="flex items-center whatsapp-header">
                                                    <div className="grow-0">
                                                        <div className="whatsapp-icon">{botDetails.businessName.charAt(0)}</div>
                                                    </div>
                                                    <div className="text-white text-sm font-medium">
                                                            <p>{botDetails.businessName}</p>
                                                    </div>
                                                </div>

                                                <div className="wa-messages p-3">
                                                    <div className="wa-message sent shadow-sm msg-pointer mt-1 p-2">
                                                        <p className="wa-text">
                                                            Hi
                                                            <span className="metadata">
                                                                <span className="time">12:00</span>
                                                                <img src={blueticks} alt={"time"} className="inline w-4 ml-0.5" />
                                                            </span> 
                                                        </p>
                                                    </div>
                                                    <div className="wa-message received shadow-sm msg-pointer mt-2 p-2">
                                                        <p className="wa-text">
                                                            Hi 👋 Welcome to {botDetails.businessName} 
                                                            <span className="metadata">
                                                                <span className="time">12:00</span>
                                                            </span> 
                                                        </p>
                                                    </div>
                                                    <div className="wa-message received shadow-sm mt-1">
                                                        <p className="wa-text p-2">
                                                            We are thrilled to have you here, How can we help you today ?
                                                            <p className="text-gray mt-1.5 leading-normal wa-text" style={{fontSize: "11px"}}>
                                                                💡 Type menu to come back to this menu at any time

                                                                <span className="metadata">
                                                                    <span className="time">12:00</span>
                                                                </span> 
                                                            </p>
                                                        </p>
                                                        <p className="text-center text-blue border-t-gray-light border-t py-1.5 flex justify-center items-center">
                                                            <svg className="inline mr-1" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.8505 2.81676C2.4928 2.81676 3.01349 2.26185 3.01349 1.57733C3.01349 0.892805 2.4928 0.337891 1.8505 0.337891C1.20819 0.337891 0.6875 0.892805 0.6875 1.57733C0.6875 2.26185 1.20819 2.81676 1.8505 2.81676Z" fill="#289ED9" />
                                                                <path d="M10.8373 2.19744H4.81086C4.54654 2.19744 4.28223 1.97209 4.28223 1.63406V1.52139C4.28223 1.2397 4.49368 0.958008 4.81086 0.958008H10.7844C11.0487 0.958008 11.3131 1.18336 11.3131 1.52139V1.6904C11.3131 1.97209 11.1016 2.19744 10.8373 2.19744Z" fill="#289ED9" />
                                                                <path d="M1.8505 7.2113C2.4928 7.2113 3.01349 6.65638 3.01349 5.97186C3.01349 5.28734 2.4928 4.73242 1.8505 4.73242C1.20819 4.73242 0.6875 5.28734 0.6875 5.97186C0.6875 6.65638 1.20819 7.2113 1.8505 7.2113Z" fill="#289ED9" />
                                                                <path d="M10.8373 6.59167H4.81086C4.54654 6.59167 4.28223 6.36632 4.28223 6.02829V5.85928C4.28223 5.57759 4.49368 5.2959 4.81086 5.2959H10.7844C11.0487 5.2959 11.3131 5.52125 11.3131 5.85928V6.02829C11.3131 6.30998 11.1016 6.59167 10.8373 6.59167Z" fill="#289ED9" />
                                                                <path d="M1.8505 11.5492C2.4928 11.5492 3.01349 10.9943 3.01349 10.3097C3.01349 9.62523 2.4928 9.07031 1.8505 9.07031C1.20819 9.07031 0.6875 9.62523 0.6875 10.3097C0.6875 10.9943 1.20819 11.5492 1.8505 11.5492Z" fill="#289ED9" />
                                                                <path d="M10.8373 10.9296H4.81086C4.54654 10.9296 4.28223 10.7042 4.28223 10.3662V10.1972C4.28223 9.91548 4.49368 9.63379 4.81086 9.63379H10.7844C11.0487 9.63379 11.3131 9.85914 11.3131 10.1972V10.3662C11.3131 10.7042 11.1016 10.9296 10.8373 10.9296Z" fill="#289ED9" />
                                                            </svg>
                                                            <span className="font-medium">Main Menu</span>
                                                        </p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grow flex">
                                            <div className="pink-gradient-border grow flex flex-col justify-between shadow-md p-4 -mt-1.0 text-left">
                                                <div>
                                                    <p className="font-medium text-lg">🛒 Get orders with WhatsApp bot</p>
                                                    <p className="text-gray mt-2">We have now enabled a virtual assistant to help your customers in their shopping journey via WhatsApp.</p>
                                                
                                                </div>
                                                <div>
                                                    <p className="text-gray mt-2">Just ask them to send a Hi on 👇🏼</p>
                                                    <div className="bg-white border border-gray-light rounded font-semibold flex items-center p-2.5 mt-2">
                                                        <img src={WhatsappIcon} alt="WhatsappIcon" className="inline mr-2 ml-1 w-5"/>
                                                        <span className="text-sm">{botDetails.wabanumber}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="grow flex justify-center text-center phone-design-container">
                                    <div className="flex flex-col items-center grow mb-8 md:mb-0 phone-design">
                                        <div style={{backgroundImage: "url("+phoneEmpty+")"}} className="phone-background">
                                            <div style={{backgroundImage: "radial-gradient(162.25% 92.46% at -23.86% -20.04%, #F3F4F6 0%, #FCFCFD 80.04%)"}}>
                                                <div className="phone-inside-qr flex items-center justify-center" style={{ backgroundImage: "url("+cornerBorders+")" }}>
                                                    <QRCode 
                                                        value={botDetails.botUrl}
                                                        size={160}
                                                        bgColor={"rgba(0,0,0,0)"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grow flex">
                                            <div className="pink-gradient-border grow flex flex-col justify-between shadow-md p-4 -mt-1.0 text-left">
                                                <div>
                                                    <p className="font-medium text-lg">🤳🏽 See the bot for yourself</p>
                                                    <p className="text-gray mt-2">Test your Whatsapp bot yourself, just scan the QR code above in your Camera.</p>
                                                </div>
                                                <div>
                                                    <p className="text-gray mt-2">or send a Hi on 👇🏼</p>
                                                    <div className="bg-white border border-gray-light rounded font-semibold flex items-center p-2.5 mt-2">
                                                        <img src={WhatsappIcon} alt="WhatsappIcon" className="inline mr-2 ml-1 w-5"/>
                                                        <span className="text-sm">{botDetails.wabanumber}</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grow flex justify-center text-center phone-design-container">
                                    <div className="flex flex-col items-center grow mb-8 md:mb-0 phone-design">
                                        <div style={{backgroundImage: "url("+phoneEmpty+")"}} className="phone-background">
                                            <div style={{backgroundImage: "url("+whatsappScreen+")"}} className="phone-inside">
                                                <div className="flex items-center whatsapp-header">
                                                    <div className="grow-0">
                                                        <div className="whatsapp-icon bg-yellow opacity-75">
                                                            <img src={loveDP} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="text-white text-sm font-medium">
                                                        <p>Family Group</p>
                                                    </div>
                                                </div>

                                                <div className="wa-messages p-3">
                                                    <div className="wa-message sent shadow-sm msg-pointer mt-1 p-2">
                                                        <p className="wa-text">
                                                            Hi, my store is now on whatsapp, Check it out 
                                                            <span className="metadata">
                                                                <span className="time">12:00</span>
                                                                <img src={blueticks} alt={"time"} className="inline w-4 ml-0.5" />
                                                            </span> 
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="wa-messages p-3">
                                                    <div className="wa-message sent shadow-sm mt-1" style={{width: "100%"}}>
                                                        <div className="flex items-center">
                                                            <span className="contact-icon m-2">{botDetails.businessName.charAt(0)}
                                                            </span>
                                                            <p className="wa-text p-2 pl-0 grow box-border">
                                                                <span className="text-blue font-medium">
                                                                    {botDetails.businessName}
                                                                </span>
                                                                <span className="metadata">
                                                                    <span className="time">12:00</span>
                                                                    <img src={blueticks} alt={"time"} className="inline w-4 ml-0.5" />
                                                                </span> 
                                                            </p>
                                                        </div>
                                                        <p className="text-center text-blue border-t-gray-light border-t py-1.5 flex justify-center items-center">
                                                            <span className="font-medium">Message</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grow flex">
                                            <div className="pink-gradient-border grow flex flex-col justify-between shadow-md p-4 -mt-1.0 text-left">
                                                <div>
                                                    <p className="font-medium text-lg">🚀 Share with your contacts</p>
                                                    <p className="text-gray mt-2">You can add {botDetails.wabanumber} as {botDetails.businessName} in your phone contacts and share it with your friends and family.</p>
                                                </div>
                                                <div>  
                                                    <p className="text-gray mt-2">or share this link 👇🏼</p>
                                                    <div className=" mt-2 border flex bg-white border-gray-light rounded overflow-hidden">
                                                        <input id="botUrlLink" className="w-full min-w-0 border-0 outline-none hover:outline-none text-sm py-2 pl-3" style={{background: "none"}} value={ botDetails.botUrl } readOnly/>
                                                        <Button 
                                                            className="px-3 shrink-0 w-fit" 
                                                            style={{fontFamily: "Poppins", textTransform: "none", fontSize: "0.875rem", fontWeight: "400", whiteSpace: "nowrap", padding: "0.5rem 0.75rem", color: "#2196F3"}}
                                                            startIcon={<ContentCopy />}
                                                            onClick={(e) => {
                                                                // navigator.clipboard.writeText(botDetails.botUrl);
                                                                var copyText = document.getElementById("botUrlLink");
                                                                copyText.select();
                                                                document.execCommand('copy');
                                                                notification("success", "Bot URL copied to clipboard!")
                                                            }}
                                                        >Copy Link</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    //new design preparation - end
                    
                    }
                </div>
            }
        </div>
        
    )
}

export default FinalScreen;