import { toast } from 'react-toastify';
const options = {
    autoClose: 3500,
    closeOnClick: true,
    hideProgressBar: false,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,

};

const notification = (type, message) => {
    switch (type) {
        case 'info':
            toast.info(message, options);
            break;
        case 'success':
            toast.success(message, options);
            break;
        case 'warning':
            toast.warn(message, options);
            break;
        case 'error':
            toast.error(message, options);
            break;
        default:
            break;
    }
};
export default notification;